import { BootstrapVueIcons, BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// import _ from "lodash";
import lodash from "lodash";
import Vuelidate from "vuelidate";
import vSelect from "vue-select";
import CKEditor from "@ckeditor/ckeditor5-vue";
import Toaster from "v-toaster";
import tinymceEditor from "vue-tinymce-editor";

// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import "v-toaster/dist/v-toaster.css";

// optional set default imeout, the default is 10000 (10 seconds).
Vue.use(Toaster, { timeout: 5000 });

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLocationArrow,
  faPhoneAlt,
  faStar,
  faMapMarkerAlt,
  faPaintBrush,
  faComments,
  faUsers,
  faPowerOff,
  faEye,
  faTimes
} from "@fortawesome/free-solid-svg-icons"; // fas
import {
  faEnvelope,
  faClock,
  faUserCircle,
  faCheckCircle,
  faUser,
  faBuilding,
  faEyeSlash
} from "@fortawesome/free-regular-svg-icons"; // far
import {
  faTwitter,
  faFacebookF,
  faGooglePlusG,
  faPinterest
} from "@fortawesome/free-brands-svg-icons"; // fab
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from "@fortawesome/vue-fontawesome";
import Notifications from "vue-notification";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vue from "vue";

import moment from "vue-moment";

library.add(
  faLocationArrow,
  faPhoneAlt,
  faStar,
  faMapMarkerAlt,
  faPaintBrush,
  faComments,
  faUsers,
  faPowerOff,
  faEye,
  faTimes
); // fas
library.add(faEnvelope, faClock, faUserCircle, faCheckCircle, faUser, faBuilding, faEyeSlash); // far
library.add(faTwitter, faFacebookF, faGooglePlusG, faPinterest); // fab
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(lodash);
Vue.use(Notifications);
Vue.use(CKEditor);

// es modules
import Editor from "@tinymce/tinymce-vue";
// commonjs require
// NOTE: default needed after require
// var Editor = require("@tinymce/tinymce-vue").default;
Vue.use(Editor);

Vue.component("v-select", vSelect);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
Vue.component("tinymce", tinymceEditor);
Vue.use(moment);

Vue.config.productionTip = false;
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
Vue.component("datepicker", DatePicker);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
