import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
// import User from '../views/User/View-User.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login
  },
  {
    path: "/viewDashboardList",
    name: "viewDashboardList",

    component: () => import("../views/dashboard/viewDashboardList.vue")
  },
  {
    path: "/viewUserList",
    name: "viewUserList",

    component: () => import("../views/UserList/viewUserList.vue")
  },
  {
    path: "/viewUserDetail/:id",
    name: "viewUserDetail",

    component: () => import("../views/UserList/viewUserDetail.vue")
  },
  {
    path: "/userInvoice/:id",
    name: "user-invoice",
    component: () => import("../views/UserList/userInvoice.vue")
  },
  {
    path: "/userPurchaseHistory/:id",
    name: "user-purchase-history",
    component: () => import("../views/UserList/userPurchaseHistory.vue")
  },
  {
    path: "/userDealsOffers/:id",
    name: "user-deals-offers",
    component: () => import("../views/UserList/userDealsOffers.vue")
  },
  {
    path: "/message",
    name: "message",
    component: () => import("../views/UserList/message.vue")
  },
  {
    path: "/hotel-message",
    name: "hotel-message",
    component: () => import("../views/HotelList/hotelMessage.vue")
  },
  {
    path: "/viewHotelList",
    name: "viewHotelList",

    component: () => import("../views/HotelList/viewHotelList.vue")
  },
  {
    path: "/viewBlogList",
    name: "viewBlogList",

    component: () => import("../views/blogs/viewBlogList.vue")
  },
  {
    path: "/wedCalculator",
    name: "wedCalculator",

    component: () => import("../views/wed-calculator/wedCalculator.vue")
  },
  {
    path: "/deal-maker",
    name: "deal-maker",

    component: () => import("../views/deal-maker/dealMaker.vue")
  },
  {
    path: "/viewDetails/:id",
    name: "viewDetails",

    component: () => import("../views/deal-maker/viewDetails.vue")
  },
  {
    path: "/deals-offers",
    name: "deal-offer",

    component: () => import("../views/deals-offers/deals-offers.vue")
  },
  {
    path: "/deals-offers-form/:id",
    name: "deals-offers-form",

    component: () => import("../views/deals-offers/deals-offers-form.vue")
  },
  {
    path: "/viewTestimonial",
    name: "viewTestimonial",

    component: () => import("../views/testimonials/viewTestimonial.vue")
  },
  {
    path: "/createDestination",
    name: "createDestination",

    component: () => import("../views/destination-expert/createDestination.vue")
  },
  {
    path: "/viewDestination",
    name: "viewDestination",

    component: () => import("../views/destination-expert/viewDestination.vue")
  },
  {
    path: "/editDestination/:id",
    name: "editDestination",

    component: () => import("../views/destination-expert/editDestination.vue")
  },
  {
    path: "/viewExpertTalks",
    name: "viewExpertTalks",

    component: () => import("../views/expert-talks/viewExpertTalks.vue")
  },

  {
    path: "/createExpertTalks",
    name: "createExpertTalks",

    component: () => import("../views/expert-talks/createExpertTalks.vue")
  },

  {
    path: "/editExpertTalks/:id",
    name: "editExpertTalks",

    component: () => import("../views/expert-talks/editExpertTalks.vue")
  },

  {
    path: "/info-details/:id",
    name: "info-details",
    component: () => import("../views/HotelList/info-details.vue")
  },
  {
    path: "/hotel-details/:id",
    name: "hotel-details",

    component: () => import("../views/HotelList/hotel-details.vue")
  },
  {
    path: "/transaction-details/:id",
    name: "transaction-details",
    component: () => import("../views/HotelList/transaction-details.vue")
  },
  {
    path: "/viewInquiryList/",
    name: "viewInquiryList",

    component: () => import("../views/inquiry/viewInquiryList.vue")
  },
  {
    path: "/inquiryDetails/:id",
    name: "inquiryDetails",

    component: () => import("../views/inquiry/inquiryDetails.vue")
  },
  {
    path: "/hotel/:id",
    // path: '/hotel',
    name: "hotel",

    component: () => import("../views/HotelList/hotel.vue")
  },
  {
    path: "/blog",
    // path: '/hotel',
    name: "blog",

    component: () => import("../views/blogs/blog.vue")
  },
  {
    path: "/editBlog",
    // path: '/hotel',
    name: "editBlog",

    component: () => import("../views/blogs/editBlog.vue")
  },
  {
    path: "/testimonials",
    // path: '/hotel',
    name: "testimonials",

    component: () => import("../views/testimonials/createTestimonial.vue")
  },
  {
    path: "/editTestimonial",
    // path: '/hotel',
    name: "editTestimonial",

    component: () => import("../views/testimonials/editTestimonial.vue")
  },
  {
    path: "/report",
    name: "report",

    component: () => import("../views/report/reportList.vue")
  },
  {
    path: "/viewReport",
    name: "viewReport",

    component: () => import("../views/report/viewReportList.vue")
  },
  {
    path: "/authorize",
    name: "authorize",

    component: () => import("../views/authorize/authorize.vue")
  },
  {
    path: "/newsletter",
    name: "newsletter",

    component: () => import("../views/newsletter/viewNewsletter.vue")
  },
  {
    path: "/shortlist",
    name: "shortlist",

    component: () => import("../views/shortlist/viewShortlist.vue")
  },
  {
    path: "/hotelInquiry",
    name: "hotelInquiry",

    component: () => import("../views/hotel-inquiry/viewHotelInquiryList.vue")
  },
  {
    path: "/hotelInquiryDetails/:id",
    name: "hotelInquiryDetails",

    component: () => import("../views/hotel-inquiry/hotelInquiryDetails.vue")
  },
  {
    path: "/offerDealsInquiryDetails/:id",
    name: "offerDealsInquiryDetails",

    component: () => import("../views/offer-deals-inquire/offerDealsInquiryDetails.vue")
  },
  {
    path: "/offerDealInquiry",
    name: "offerDealInquiry",

    component: () => import("../views/offer-deals-inquire/viewOfferDealsInquireList.vue")
  },
  {
    path: "/createUserLead",
    name: "createUserLead",

    component: () => import("../views/user-leads/createUserLead.vue")
  },
  {
    path: "/viewUserLead",
    name: "viewUserLead",

    component: () => import("../views/user-leads/viewUserLead.vue")
  },
  {
    path: "/viewLeadDetail/:id",
    name: "viewLeadDetail",

    component: () => import("../views/user-leads/viewLeadDetail.vue")
  },

  {
    path: "/allProfileList",
    name: "allProfileList",

    component: () => import("../views/dashboard/allProfileList.vue")
  },
  {
    path: "/allLeadList",
    name: "allLeadList",

    component: () => import("../views/dashboard/allLeadList.vue")
  },
  {
    path: "/viewMetaDataList",
    name: "viewMetaDataList",
    component: () => import("../views/meta-data/viewMetaDataList.vue")
  },
  {
    path: "/editMetaDataList/:id",
    name: "editMetaDataList",
    component: () => import("../views/meta-data/editMetaDataList.vue")
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;
