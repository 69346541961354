<template>
  <div>
    <div class="account-user-sec">
      <div class="account-sec">
        <div class="account-top-bar">
          <div class="container">
            <div class="logo">
              <a ui-sref="login" title="Admin">
                <h4>Wohlig Backend</h4>
              </a>
            </div>
          </div>
        </div>
        <div class="acount-sec">
          <div class="container">
            <notifications group="foo" />
            <div class="row justify-content-md-center">
              <div class="col-md-4 col-md-offset-4">
                <div class="contact-sec formPart">
                  <b-form>
                    <!-- Email -->
                    <div>
                      <div
                        class="form-group"
                        :class="{ 'form-group--error': $v.user.email.$error }"
                      >
                        <label for="email">Emails</label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="name@gmail.com"
                          v-model.trim="user.email"
                          @input="setEmail()"
                        />
                        <div
                          class="error"
                          v-if="errors.email"
                        >
                          <p class="error"><b>{{errors.email}}</b></p>
                        </div>
                      </div>
                    </div>
                    <!-- Email end -->
                    <!-- password -->
                    <div>
                      <label>Password</label>
                      <div
                        class="form-group floating-label-form-group controls"
                        :class="{
                          'form-group--error': $v.user.password.$error,
                        }"
                      >
                        <div class="pass-box">
                          <input
                            type="password"
                            id="password"
                            class="form-control pass-box mr-2"
                            placeholder="Password"
                            v-model.trim="user.password"
                            @input="setPassword()"
                            required
                          />
                          <div
                            class="input-group-addon icon-eye"
                            v-if="!showPassword"
                          >
                            <a v-on:click="myPassword()">
                              <font-awesome-icon :icon="['far', 'eye-slash']" />
                            </a>
                          </div>
                          <div
                            class="input-group-addon icon-eye"
                            v-if="showPassword"
                          >
                            <a v-on:click="myPassword()">
                              <font-awesome-icon :icon="['fas', 'eye']" />
                            </a>
                          </div>
                        </div>
                        <div
                          class="error"
                          v-if="errors.password"
                        >
                          <p class="error"><b>{{errors.password}}</b></p>
                        </div>
                      </div>
                    </div>
                    <div class="form-group text-center">
                      <!-- <router-link to="/viewUserList"> -->
                        <!-- <button class="btn btn-primary px-5">Login</button> -->
                        <button
                          class="btn btn-primary px-5"
                          type="button"
                          v-on:click="onSubmitUser()"
                        >
                          Login
                        </button>
                      <!-- </router-link> -->
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import apiservice from "@/service/apiservice";

import {
  required,
  minLength,
  maxLength,
  between,
  sameAs,
  email,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      showPassword: false,
      errors: {},
      user: {
        email: "",
        password: "",
      },
      show: true,
    };
  },
  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {

    login() {

      apiservice.login(this.user, (data)=>{
        if(data.code === 200){
          this.$router.push('viewUserList') 

        }else{
          if(data.err.response && data.err.response.data){
            this.errors = data.err.response.data
          }else{
            
            alert("Something went wrong!")
          }
        }
      })
    },
    setEmail() {
      this.$v.user.email.$touch();
    },
    setPassword() {
      this.$v.user.password.$touch();
    },
    onSubmitUser() {

      
      let errors= {};

      if (!this.user.email) {
        errors.email = "Email is required"
      }
      if (!this.user.password) {
        errors.password = "Password is required.";
      }

      if(Object.keys(errors).length > 0){
        console.log("errors found")
        this.errors = errors;
        return
      }
      this.errors={}
      this.login()
    },
    // onReset(evt) {
    //   evt.preventDefault();
    //   // Reset our form values
    //   this.form.email = "";
    //   this.form.password = "";
    //   // Trick to reset/clear native browser form validation state
    //   this.show = false;
    //   this.$nextTick(() => {
    //     this.show = true;
    //   });
    // },
    // for password
    myPassword() {
      const x = document.getElementById("password");
      if (x.type == "password") {
        x.type = "text";
        this.showPassword = true;
      } else {
        x.type = "password";
        this.showPassword = false;
      }
    },
  },
};
</script>
