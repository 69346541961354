/* eslint-disable no-param-reassign */
import Vue from "vue";
import _ from "lodash";
import nuxtStorage from "nuxt-storage";

let global;
if (process.env.NODE_ENV == "production") {
  //Production Level

  global = {
    adminurl: "https://beapi.weddestinations.com/",
    uploadUrl: "https://beuplaod.weddestinations.com/",
    imageUrl: "https://beuplaod.weddestinations.com/",
    cognitoUrl : 'https://beusermgmt.weddestinations.com/', //wed dest user mng master
    // dashboardUrl: "https://cdn.jsdelivr.net/gh/highcharts/highcharts@v7.0.0/samples/data/usdeur.json",

    setHotel(data) {
      nuxtStorage.localStorage.setData("hotel", data, 1, "d");
    },
    getHotel() {
      return nuxtStorage.localStorage.getData("hotel");
    },

    getMessage(module, code, response) {
      const moduleMsg = _.find(this.messages, ["name", module]);

      return {
        msg: moduleMsg.messages[code],
        code: response.status,
        data: response.data
      };
    },

    messages: [
      // logout
      {
        name: "logout",
        messages: {
          200: "logout successfully",
          500: "Internal server error",
          default: "Some error occurred."
        }
      },
      {
        name: "logList",
        messages: {
          200: "User List Displayed",
          500: "Internal server error",
          default: "Some error occurred."
        }
      }
    ]
  };
} else {
  //Development or testing level

  global = {
    adminurl: "http://localhost:4006/",
    uploadUrl: "http://localhost:1330/",
    imageUrl: "http://localhost:1330/",
    cognitoUrl : 'http://localhost:4001/', //wed dest user mng master
    // dashboardUrl: "https://cdn.jsdelivr.net/gh/highcharts/highcharts@v7.0.0/samples/data/usdeur.json",

    setHotel(data) {
      nuxtStorage.localStorage.setData("hotel", data, 1, "d");
    },
    getHotel() {
      return nuxtStorage.localStorage.getData("hotel");
    },

    getMessage(module, code, response) {
      const moduleMsg = _.find(this.messages, ["name", module]);

      return {
        msg: moduleMsg.messages[code],
        code: response.status,
        data: response.data
      };
    },

    messages: [
      // logout
      {
        name: "logout",
        messages: {
          200: "logout successfully",
          500: "Internal server error",
          default: "Some error occurred."
        }
      },
      {
        name: "logList",
        messages: {
          200: "User List Displayed",
          500: "Internal server error",
          default: "Some error occurred."
        }
      }
    ]
  };
}

export default global;
